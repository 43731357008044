@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap&subset=latin-ext);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  line-height: 20px; }

.nav-item {
  color: #a2a5b9; }

a {
  text-decoration: none;
  color: inherit; }

.nav-category {
  color: #4c4e6f;
  text-transform: uppercase; }

.navbar-text {
  font-size: 15px;
  margin: 0 5px;
  font-weight: 500;
  color: #181c32; }

.breadcrumb {
  color: #74788d;
  font-size: 10px; }

.heading {
  font-size: 15px;
  color: #48465b;
  font-weight: 500;
  line-height: 20px; }
  .heading__date, .heading__info {
    font-size: 13px;
    line-height: 20px;
    color: rgba(89, 93, 110, 0.5);
    padding-left: 20px; }
    .heading__date span, .heading__date strong, .heading__info span, .heading__info strong {
      display: inline-block;
      padding: 0 6px;
      font-size: 13px;
      line-height: 20px;
      color: #595D6E; }
    .heading__date strong, .heading__info strong {
      font-weight: 600; }

.search-text {
  color: #4950577c;
  font-weight: 400; }

.table-head th {
  color: #595d6e;
  font-weight: 500;
  text-align: left;
  padding: 15px; }

.table-body td {
  color: #595d6e;
  font-weight: 400;
  text-align: left; }

.pagination-info {
  margin-left: 14px;
  color: #646c9a;
  font-weight: 100; }

.label-form {
  color: #646c9a; }
  .label-form.answers {
    display: flex;
    justify-content: space-between;
    margin: 30px 0; }

.platform-name {
  color: #595d6e;
  font-weight: 100;
  margin-right: 10px; }

.country-label {
  color: #a2a5b9;
  font-weight: 100; }

.popup-text {
  padding: 25px 30px;
  padding-bottom: 37px;
  color: #595d6e;
  font-weight: 100; }
  .popup-text strong {
    font-weight: 600; }

.check-group .label-form {
  color: #646c9a; }

.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin: 0 0 20px; }
  .dashboard__rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .dashboard__rating__rate {
      display: flex;
      align-items: center; }
      .dashboard__rating__rate .value {
        font-size: 25px;
        line-height: 25px;
        color: #181c32;
        font-weight: 600;
        margin-right: 16px; }
      .dashboard__rating__rate .rating-wrapper span {
        margin: 0 2px; }
    .dashboard__rating__comments span {
      font-size: 13px;
      line-height: 20px;
      color: #7e8299; }
  .dashboard__tabs {
    display: flex;
    background-color: #f2f3f8; }
    .dashboard__tabs .tab {
      flex: 1 1;
      background-color: #ebecf3;
      padding: 9px 0;
      text-align: center;
      cursor: pointer;
      position: relative; }
      .dashboard__tabs .tab:last-child::before {
        display: none; }
      .dashboard__tabs .tab:hover {
        background-color: #e5e6ef; }
      .dashboard__tabs .tab.active {
        background-color: white;
        position: relative; }
        .dashboard__tabs .tab.active:hover {
          background-color: white; }
        .dashboard__tabs .tab.active:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #5d78ff;
          height: 2px; }
        .dashboard__tabs .tab.active:before {
          display: none; }
      .dashboard__tabs .tab a {
        display: inline-block;
        width: 100%; }
  .dashboard--small {
    flex-basis: 49.2%; }
  .dashboard__button-section {
    padding: 20px 30px; }
  .dashboard__table {
    width: 100%; }
    .dashboard__table th {
      text-align: left;
      border-bottom: 1px solid #ebedf2;
      font-weight: 500;
      padding: 10px; }
    .dashboard__table td {
      border-bottom: 1px solid #ebedf2;
      padding: 10px;
      vertical-align: middle; }
      .dashboard__table td .btn.btn-icon {
        padding: 0; }
  .dashboard__list-item {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    position: relative; }
    .dashboard__list-item .country {
      margin-right: auto; }
      .dashboard__list-item .country-label--hidden {
        visibility: hidden; }
    .dashboard__list-item .no-margin {
      margin: 0; }
    .dashboard__list-item:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ebedf2; }
  .dashboard__form {
    padding: 30px; }
    .dashboard__form__submit {
      padding: 20px 30px; }
    .dashboard__form__footer {
      border-top: 1px solid #ebedf2;
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px; }
      .dashboard__form__footer.md {
        justify-content: flex-start; }
        .dashboard__form__footer.md .box {
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: flex-end; }
    .dashboard__form--pr-10 {
      padding-right: 10px; }
  .dashboard__text {
    padding: 30px; }
    .dashboard__text p {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 5px; }
    .dashboard__text strong {
      font-weight: 500; }
  .dashboard__image {
    padding: 20px;
    text-align: center; }
    .dashboard__image img {
      max-width: 100%; }
  .dashboard__channel-label {
    padding: 30px;
    padding-top: 0; }
    .dashboard__channel-label .channel-label-text {
      margin-bottom: 11px; }
  .dashboard__header {
    display: flex;
    padding: 20px 30px 20px;
    align-items: center;
    border-bottom: 1px solid #ebedf2; }
    .dashboard__header--edit {
      padding: 18px 30px; }
  .dashboard__title {
    margin-right: auto;
    margin-left: 10px; }
    .dashboard__title.editor {
      margin-right: 5px; }
  .dashboard__searchfield {
    padding: 18px 30px;
    display: flex; }
    .dashboard__searchfield .search-box {
      flex: 1 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px; }

.main-dashboard {
  min-height: 100vh;
  padding-top: 82px;
  background-color: #f2f3f8;
  position: relative; }
  .main-dashboard__container {
    padding: 20px 20px 60px; }

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd; }

.icon__container path {
  fill: #5d78ff; }

.icon__container circle {
  fill: #5d78ff; }

.icon__container .svg-rect {
  fill: #5d78ff; }

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.column-1 {
  flex-basis: calc(33.3333% - 10px); }
  .column-1--container {
    display: flex;
    flex-direction: column; }

.column-2 {
  flex-basis: calc(66.666% - 10px); }

.column-3 {
  flex-basis: 100%; }

.column-half {
  flex-basis: calc(50% - 10px); }

.table {
  width: 100%;
  background-color: transparent; }
  .table__header tr {
    border-bottom: 1px solid #ebedf2; }
  .table__header th {
    padding: 18px 0px; }
    .table__header th:first-child {
      padding-left: 28px; }
  .table__body tr {
    cursor: pointer;
    border-bottom: 1px solid #ebedf2; }
    .table__body tr:hover {
      background-color: #f9f9f9; }
  .table__body td {
    font-family: "Poppins";
    padding: 20px 0px;
    vertical-align: middle;
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }
  .table__body tr td:first-child {
    padding-left: 28px; }
    .table__body tr td:first-child.child {
      position: relative; }
      .table__body tr td:first-child.child--1 {
        padding-left: 63px; }
      .table__body tr td:first-child.child--2 {
        padding-left: 98px; }
  .table__body tr .category-span {
    position: relative; }
    .table__body tr .category-span::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -44px;
      width: 23px;
      height: 2px;
      background-color: #597dff;
      opacity: 0.4;
      top: 49%; }
    .table__body tr .category-span--hide::before {
      display: none; }
  .table__double-label {
    display: inline-block;
    margin: 5px;
    white-space: nowrap;
    font-family: "Poppins", sans-serif; }
  .table__label {
    background-color: #ececec;
    padding: 2px 8px;
    margin-right: 8px;
    font-size: 11px;
    line-height: 16px;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    font-weight: 500; }
    .table__label--green {
      color: #0abbbb;
      background-color: rgba(10, 187, 187, 0.2); }
    .table__label--red {
      color: #fd3939;
      background-color: rgba(253, 57, 57, 0.2); }
    .table__label--yellow {
      color: #ffb822;
      background-color: rgba(255, 184, 34, 0.2); }
    .table__label--violet {
      color: #6744f1;
      background-color: rgba(103, 68, 241, 0.2); }
  .table__label-left {
    background-color: #ececec;
    padding: 3px 8px;
    font-size: 11px;
    border-radius: 4px 0 0 4px;
    font-weight: 500; }
    .table__label-left--green {
      color: #0abbbb;
      background-color: rgba(10, 187, 187, 0.2); }
    .table__label-left--red {
      color: #fd3939;
      background-color: rgba(253, 57, 57, 0.2); }
    .table__label-left--yellow {
      color: #ffb822;
      background-color: rgba(255, 184, 34, 0.2); }
    .table__label-left--violet {
      color: #6744f1;
      background-color: rgba(103, 68, 241, 0.2); }
  .table__label-right {
    padding: 2px 8px;
    font-size: 11px;
    border: 1px solid #ececec;
    border-radius: 0 4px 4px 0;
    font-weight: 500; }
    .table__label-right--orange {
      color: #ff5a00;
      border-color: #ff5a00; }
    .table__label-right--blue {
      color: #0064d2;
      border-color: #0064d2; }
    .table__label-right--green {
      color: #99cc00;
      border-color: #99cc00; }
  .table__platform {
    margin-right: 10px;
    line-height: 16px;
    padding: 2px 8px;
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: 1px solid #ececec;
    border-radius: 4px; }
    .table__platform--orange {
      color: #ff5a00;
      border-color: #ff5a00; }
    .table__platform--blue {
      color: #0064d2;
      border-color: #0064d2; }
    .table__platform--green {
      color: #99cc00;
      border-color: #99cc00; }
  .table__icon {
    margin-right: 10px;
    cursor: pointer; }
    .table__icon path {
      fill: #91a0da; }
    .table__icon--purple svg {
      -webkit-transform: translateY(-2px) scale(1.2);
              transform: translateY(-2px) scale(1.2); }
    .table__icon--purple path {
      fill: #fd397a; }
  .table__inputs td {
    padding-right: 30px; }
  .table__photo {
    max-width: 40px;
    max-height: 40px;
    display: block;
    margin-top: auto;
    margin-bottom: auto; }

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f3f8;
  display: flex;
  justify-content: center;
  align-items: center; }

.tree {
  color: #595d6e;
  padding: 0 20px; }
  .tree__line {
    position: absolute;
    z-index: 2; }
    .tree__line--vertical {
      top: 0px;
      bottom: 5px;
      left: 0;
      border-left: 1px dashed rgba(89, 125, 255, 0.4);
      -webkit-transform: translate(2px, -30px);
              transform: translate(2px, -30px); }
    .tree__line--horizontal {
      top: 50%;
      left: 23px;
      width: 16px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      border-bottom: 1px dashed rgba(89, 125, 255, 0.4); }
  .tree__item {
    width: 100%; }
    .tree__item > .table {
      width: calc(100% + 40px);
      margin-left: -20px;
      border-bottom: 1px solid #ebedf2; }
      .tree__item > .table td {
        padding: 20px 20px 20px 0; }
      .tree__item > .table .button__tree {
        margin-right: 5px; }
      .tree__item > .table .table__body tr td {
        width: 20%; }
        .tree__item > .table .table__body tr td:first-child {
          min-width: 400px; }
    .tree__item > .tree__list {
      position: relative; }
      .tree__item > .tree__list > .tree__item > .table {
        position: relative;
        margin-left: -20px; }
        .tree__item > .tree__list > .tree__item > .table .table__body tr td:first-child {
          padding-left: 50px; }

.tooltip {
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(82, 63, 105, 0.15); }
  .tooltip p {
    font-size: 11px;
    line-height: 16px;
    color: #48465b; }

.text-editor {
  margin: 0 0 20px; }
  .text-editor-label {
    font-size: 13px;
    line-height: 20px;
    color: #646c9a;
    margin-bottom: 2px; }
  .text-editor .rc-md-editor {
    border-radius: 4px;
    overflow: hidden; }

.comment {
  display: flex;
  align-items: flex-start;
  padding-right: 20px; }
  .comment__image {
    flex-basis: 60px;
    max-width: 60px;
    min-width: 60px;
    padding-right: 20px; }
    .comment__image svg {
      max-width: 100%; }
  .comment__text .user {
    font-size: 13px;
    line-height: 20px;
    color: rgba(24, 28, 50, 0.9);
    font-weight: 500; }
  .comment__text .content {
    font-size: 13px;
    line-height: 20px;
    font-weight: 300; }

.answer-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }

.answer-correct {
  flex-basis: 50px;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px; }
  .answer-correct input {
    display: none; }
    .answer-correct input:checked + label::before {
      border-color: #3699ff; }
    .answer-correct input:checked + label::after {
      opacity: 1; }
  .answer-correct label {
    cursor: pointer;
    position: relative; }
    .answer-correct label::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid #e2e5ec;
      border-radius: 8px;
      background-color: #fff;
      transition: 0.2s; }
    .answer-correct label::after {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #3699ff;
      transition: 0.2s;
      opacity: 0; }

.answer-content {
  flex: 1 1; }

.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -10px 10px; }
  .form-row .radio {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid #e2e5ec;
    border-radius: 50%;
    position: relative; }
    .form-row .radio.active {
      border-color: #3699ff; }
      .form-row .radio.active:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #3699ff;
        padding: 4px;
        top: 28%;
        left: 21%; }
  .form-row .form-group {
    margin: 0 10px; }
  .form-row:last-child .form-group {
    margin-bottom: 0; }
  .form-row.grid-2 .form-group {
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px); }
  .form-row.grid-3 .form-group {
    flex-basis: calc(33.3% - 20px);
    max-width: calc(33.3% - 20px); }
  .form-row.grid-4 .form-group {
    flex-basis: calc(25% - 20px);
    max-width: calc(25% - 20px); }

.form-group {
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: 15px; }
  .form-group.label-aside .inp-wrapper {
    display: flex;
    align-items: center; }
    .form-group.label-aside .inp-wrapper .inp-group,
    .form-group.label-aside .inp-wrapper .check-group {
      width: 100%; }
    .form-group.label-aside .inp-wrapper label {
      width: initial;
      min-width: 70px;
      text-align: right;
      padding-right: 20px;
      white-space: nowrap; }
  .form-group.label-aside.error .inp-error {
    padding-left: 80px; }
  .form-group label {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #646c9a; }
  .form-group.error label,
  .form-group.error .inp-error {
    color: #ff0000; }
  .form-group.error .inp-group input {
    border-color: #ff0000; }
  .form-group.error .textarea-group textarea {
    border-color: #ff0000; }
  .form-group.error .inp-error {
    font-size: 11px;
    line-height: 14px;
    padding: 5px 0 0 5px; }
  .form-group.error .postfix,
  .form-group.error .prefix {
    border-color: #ff0000;
    color: #ff0000; }

.input-disabled {
  background-color: #f7f8fa;
  font-size: 13px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none; }

.inp-wrapper {
  width: 100%; }
  .inp-wrapper .inp-group {
    display: flex; }
    .inp-wrapper .inp-group input {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #5db63b;
      width: 100%;
      outline: none;
      transition: 0.2s; }
      .inp-wrapper .inp-group input:focus, .inp-wrapper .inp-group input.visited {
        border-color: #9aabff; }
      .inp-wrapper .inp-group input[type='hidden'] {
        display: none; }
    .inp-wrapper .inp-group .form-span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-size: 13px;
      line-height: 15px;
      color: #495057;
      background-color: #f7f8fa;
      border: 1px solid #5db63b;
      padding: 10px; }
    .inp-wrapper .inp-group .postfix {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px; }
      .inp-wrapper .inp-group .postfix-color {
        width: 16px;
        height: 16px;
        background-color: #5db63b;
        border: 1px solid #5db63b; }
    .inp-wrapper .inp-group .prefix {
      margin-right: -3px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }

.check-group {
  margin-top: 22px;
  padding-left: 25px; }
  .check-group input {
    display: none; }
    .check-group input:checked + label::after {
      opacity: 1; }
  .check-group label {
    cursor: pointer;
    color: #595d6e;
    position: relative; }
    .check-group label::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      background-color: #fff;
      border: 1px solid #d1d7e2;
      border-radius: 3px; }
    .check-group label::after {
      content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiDQoJaWQ9IkxheWVyXzEiDQoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIg0KCXhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCXg9IjBweCINCgl5PSIwcHgiDQoJdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSINCgl4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8cGF0aCBkPSJNNTA2LjIzMSw3NS41MDhjLTcuNjg5LTcuNjktMjAuMTU4LTcuNjktMjcuODQ5LDBsLTMxOS4yMSwzMTkuMjExTDMzLjYxNywyNjkuMTYzYy03LjY4OS03LjY5MS0yMC4xNTgtNy42OTEtMjcuODQ5LDANCgkJCWMtNy42OSw3LjY5LTcuNjksMjAuMTU4LDAsMjcuODQ5bDEzOS40ODEsMTM5LjQ4MWM3LjY4Nyw3LjY4NywyMC4xNiw3LjY4OSwyNy44NDksMGwzMzMuMTMzLTMzMy4xMzYNCgkJCUM1MTMuOTIxLDk1LjY2Niw1MTMuOTIxLDgzLjE5OCw1MDYuMjMxLDc1LjUwOHoiIC8+DQo8L3N2Zz4=");
      position: absolute;
      top: 1px;
      left: -19px;
      width: 10px;
      height: 10px;
      opacity: 0;
      transition: 0.2s; }
  .check-group.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .check-group .additional-info {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }

.check-label-aside {
  display: flex;
  align-items: baseline; }
  .check-label-aside__text {
    min-width: 70px;
    font-size: 13px;
    line-height: 20px;
    color: #646c9a; }

.check-main-label {
  color: #646c9a; }
  .check-main-label + .check-group {
    margin-top: 16px; }

.md-wrapper {
  padding: 15px 0; }
  .md-wrapper .form-row {
    width: 100%;
    padding: 0 20px;
    margin-left: 0; }
    .md-wrapper .form-row .rc-md-navigation {
      background: #fff; }
      .md-wrapper .form-row .rc-md-navigation .navigation-nav .button-wrap .button {
        display: none; }
      .md-wrapper .form-row .rc-md-navigation .navigation-nav .button-wrap .button-type-bold,
      .md-wrapper .form-row .rc-md-navigation .navigation-nav .button-wrap .button-type-italic,
      .md-wrapper .form-row .rc-md-navigation .navigation-nav .button-wrap .button-type-underline {
        display: block; }
    .md-wrapper .form-row .rc-md-editor {
      border-radius: 5px; }
    .md-wrapper .form-row .rc-md-editor .editor-container .sec-md .input {
      padding-top: 20px; }
      .md-wrapper .form-row .rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar {
        display: none; }
    .md-wrapper .form-row .rc-md-editor .tool-bar {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0; }

.inp-wrapper {
  width: 100%; }
  .inp-wrapper .inp-group {
    display: flex; }
    .inp-wrapper .inp-group input {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #5db63b;
      width: 100%;
      outline: none;
      transition: 0.2s; }
      .inp-wrapper .inp-group input:focus, .inp-wrapper .inp-group input.visited {
        border-color: #9aabff; }
      .inp-wrapper .inp-group input[type='hidden'] {
        display: none; }
    .inp-wrapper .inp-group .form-span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-size: 13px;
      line-height: 15px;
      color: #495057;
      background-color: #f7f8fa;
      border: 1px solid #5db63b;
      padding: 10px; }
    .inp-wrapper .inp-group .postfix {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px; }
      .inp-wrapper .inp-group .postfix-color {
        width: 16px;
        height: 16px;
        background-color: #5db63b;
        border: 1px solid #5db63b; }
    .inp-wrapper .inp-group .prefix {
      margin-right: -3px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }

.textarea-wrapper {
  width: 100%; }
  .textarea-wrapper .textarea-group {
    display: flex; }
    .textarea-wrapper .textarea-group textarea {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #5db63b;
      width: 100%;
      outline: none;
      transition: 0.2s;
      font-size: 13px;
      line-height: 19px; }
      .textarea-wrapper .textarea-group textarea:focus, .textarea-wrapper .textarea-group textarea.visited {
        border-color: #9aabff; }

.check-group {
  margin-top: 22px;
  padding-left: 25px; }
  .check-group input {
    display: none; }
    .check-group input:checked + label::after {
      opacity: 1; }
  .check-group label {
    cursor: pointer;
    color: #595d6e;
    position: relative; }
    .check-group label::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      background-color: #fff;
      border: 1px solid #d1d7e2;
      border-radius: 3px; }
    .check-group label::after {
      content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiDQoJaWQ9IkxheWVyXzEiDQoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIg0KCXhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCXg9IjBweCINCgl5PSIwcHgiDQoJdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSINCgl4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8cGF0aCBkPSJNNTA2LjIzMSw3NS41MDhjLTcuNjg5LTcuNjktMjAuMTU4LTcuNjktMjcuODQ5LDBsLTMxOS4yMSwzMTkuMjExTDMzLjYxNywyNjkuMTYzYy03LjY4OS03LjY5MS0yMC4xNTgtNy42OTEtMjcuODQ5LDANCgkJCWMtNy42OSw3LjY5LTcuNjksMjAuMTU4LDAsMjcuODQ5bDEzOS40ODEsMTM5LjQ4MWM3LjY4Nyw3LjY4NywyMC4xNiw3LjY4OSwyNy44NDksMGwzMzMuMTMzLTMzMy4xMzYNCgkJCUM1MTMuOTIxLDk1LjY2Niw1MTMuOTIxLDgzLjE5OCw1MDYuMjMxLDc1LjUwOHoiIC8+DQo8L3N2Zz4=");
      position: absolute;
      top: 1px;
      left: -19px;
      width: 10px;
      height: 10px;
      opacity: 0;
      transition: 0.2s; }
  .check-group.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .check-group .additional-info {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }

.check-label-aside {
  display: flex;
  align-items: baseline; }
  .check-label-aside__text {
    min-width: 70px;
    font-size: 13px;
    line-height: 20px;
    color: #646c9a; }

.check-main-label {
  color: #646c9a; }
  .check-main-label + .check-group {
    margin-top: 16px; }

button {
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer; }

.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  padding: 5px 15px;
  border: 1px solid #e2e5ec;
  color: #595d6e;
  transition: 0.2s; }
  .btn:disabled {
    pointer-events: none;
    background-color: #9c9c9c;
    opacity: 0.7; }
  .btn svg {
    margin-right: 5px; }
  .btn:hover {
    border-color: #595d6e; }
  .btn:active {
    -webkit-transform: scale(0.93);
            transform: scale(0.93); }
  .btn-lg {
    background-color: #9c9c9c;
    padding: 8px 18px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: #fff; }
  .btn-sm {
    background-color: #9c9c9c;
    padding: 6px 18px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: #fff;
    box-shadow: 0px 3px 16px #5a78ff26; }
  .btn__tree {
    margin-right: 4px;
    margin-left: -13px; }
  .btn-plus {
    position: relative;
    padding-left: 35px; }
    .btn-plus:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      top: 48%;
      left: 15px;
      background-color: #fff; }
    .btn-plus:after {
      content: '';
      top: 48%;
      left: 15px;
      background-color: white;
      position: absolute;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      width: 12px;
      height: 1px; }
  .btn-blue {
    background-color: #567cfb; }
  .btn-pink {
    background-color: #fd397a; }
  .btn-green {
    background-color: #0abe8c; }
  .btn-cancel {
    background-color: white;
    border: 1px solid #e2e5ec;
    color: #595d6e;
    min-width: 100px;
    justify-content: center; }
  .btn-icon {
    border: none;
    padding: 5px; }
    .btn-icon svg {
      margin: 0; }

.label {
  display: inline-block;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.81px;
  border-radius: 2px;
  padding: 4px 8px;
  background: transparent;
  color: #fff;
  font-weight: 300; }
  .label-sm {
    padding: 2px 4px;
    font-size: 10px; }
  .label-bold {
    font-weight: 600; }
  .label-round {
    border-radius: 4px; }
  .label-up {
    text-transform: uppercase; }
  .label--danger {
    background-color: #FD397A; }
  .label--warning {
    background-color: #ffb822; }
  .label--info {
    background-color: #5D78FF; }
  .label--info-light {
    background-color: #44B2F1; }
  .label--success {
    background-color: #0ABB87; }
  .label--default {
    background-color: #93A2DD; }
  .label--purple {
    background-color: #AE22FF; }

.tree {
  color: #595d6e;
  padding: 0 20px; }
  .tree__line {
    position: absolute;
    z-index: 2; }
    .tree__line--vertical {
      top: 0px;
      bottom: 5px;
      left: 0;
      border-left: 1px dashed rgba(89, 125, 255, 0.4);
      -webkit-transform: translate(2px, -30px);
              transform: translate(2px, -30px); }
    .tree__line--horizontal {
      top: 50%;
      left: 23px;
      width: 16px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      border-bottom: 1px dashed rgba(89, 125, 255, 0.4); }
  .tree__item {
    width: 100%; }
    .tree__item > .table {
      width: calc(100% + 40px);
      margin-left: -20px;
      border-bottom: 1px solid #ebedf2; }
      .tree__item > .table td {
        padding: 20px 20px 20px 0; }
      .tree__item > .table .button__tree {
        margin-right: 5px; }
      .tree__item > .table .table__body tr td {
        width: 20%; }
        .tree__item > .table .table__body tr td:first-child {
          min-width: 400px; }
    .tree__item > .tree__list {
      position: relative; }
      .tree__item > .tree__list > .tree__item > .table {
        position: relative;
        margin-left: -20px; }
        .tree__item > .tree__list > .tree__item > .table .table__body tr td:first-child {
          padding-left: 50px; }

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  overflow-y: scroll; }

.popup {
  max-width: 424px;
  margin: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s; }
  .popup.active {
    opacity: 1; }
  .popup__header {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebedf2; }
    .popup__header button:only-child {
      margin-left: auto; }
    .popup__header h2 {
      font-size: 16px;
      line-height: 20%;
      font-weight: 500; }
  .popup__body {
    padding: 20px; }
  .popup__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 15px 10px 15px;
    border-top: 1px solid #ebedf2; }
    .popup__footer button {
      margin-left: 11px; }
    .popup__footer img {
      vertical-align: middle; }
  .popup.popup__login .popup__body {
    padding: 20px; }
    .popup.popup__login .popup__body .login_button {
      text-align: center; }

.pagination {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .pagination-left {
    display: flex;
    align-items: center; }
  .pagination-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #93a2dd;
    font-size: 13px;
    line-height: 20px;
    padding: 3px;
    font-weight: 500;
    border-radius: 4px;
    margin-right: 5px;
    background-color: #fbfbff;
    min-width: 30px;
    transition: 0.2s; }
    .pagination-box svg {
      max-width: 15px; }
    .pagination-box.active {
      background-color: #5d78ff;
      color: white; }
    .pagination-box.arrow {
      background-color: #f0f3ff; }
      .pagination-box.arrow path {
        fill: #93a2dd; }
      .pagination-box.arrow[disabled] {
        pointer-events: none;
        background-color: #fbfbff; }
        .pagination-box.arrow[disabled] path {
          fill: #dfe3f5; }

.breadcrumbs__container {
  padding: 5px 8px;
  background-color: #fff;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
  position: fixed;
  z-index: 10;
  top: 52px;
  left: 265px;
  right: 0;
  transition: 0.2s; }

.breadcrumbs__list {
  display: flex;
  align-items: center; }

.breadcrumbs__item {
  display: inline-flex;
  align-items: center; }
  .breadcrumbs__item:not(:last-child)::after {
    content: '';
    display: inline-block;
    background: url(/static/media/breadcrumbs-arrow.3245a8a4.svg);
    background-size: 8px 15px;
    background-repeat: no-repeat;
    width: 8px;
    height: 15px;
    margin-bottom: 2px; }
  .breadcrumbs__item button,
  .breadcrumbs__item span {
    display: inline-block;
    padding: 0 10px;
    font-size: 11px;
    line-height: 20px;
    color: #74788d; }
  .breadcrumbs__item button {
    cursor: pointer; }
    .breadcrumbs__item button:hover {
      text-decoration: underline; }

.actionbar {
  background: #fff;
  box-shadow: 0px 0px 13px #523f690c;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 265px;
  transition: 0.2s; }
  .actionbar__container {
    padding: 10px 20px;
    display: flex;
    align-items: center; }
    .actionbar__container.align-space {
      justify-content: space-between; }
    .actionbar__container.align-right {
      justify-content: flex-end; }
  .actionbar__group {
    display: flex;
    align-items: center; }
    .actionbar__group .btn {
      margin-left: 20px; }
      .actionbar__group .btn:only-child {
        margin-left: 0; }

.nav-category {
  height: 40px;
  padding: 12px 28px;
  cursor: default; }

.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  height: 40px;
  transition: 0.3s; }
  .nav-item__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px); }
    .nav-item__trigger.button {
      text-align: left; }
      .nav-item__trigger.button .nav-item__title {
        margin-left: 0; }
  .nav-item__button {
    transition: 0.3s; }
  .nav-item path[fill="#494b74"] {
    transition: 0.3s;
    fill: #494b74; }
  .nav-item .svg-rect {
    transition: 0.3s;
    fill: #494b74; }
  .nav-item.active, .nav-item:hover {
    background-color: #1a1a27; }
    .nav-item.active path[fill="#494b74"], .nav-item:hover path[fill="#494b74"] {
      fill: #5d78ff !important; }
    .nav-item.active .svg-rect, .nav-item:hover .svg-rect {
      fill: #5d78ff !important; }
    .nav-item.active .nav-item__button, .nav-item:hover .nav-item__button {
      opacity: 0.7; }
  .nav-item__title {
    flex-grow: 1;
    margin-left: 12px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 0;
    line-height: 20px;
    color: #a2a5b9; }
    .nav-item__title:hover {
      color: #dcdde5; }
  .nav-item__button {
    width: 16px;
    height: 16px;
    opacity: 0.2;
    border-radius: 100%;
    padding: 0;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    border: 1px solid #5d78ff;
    position: relative; }
    .nav-item__button:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 7px;
      background-color: #5d78ff;
      z-index: 5;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .nav-item__button:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 7px;
      background-color: #5d78ff;
      z-index: 5;
      -webkit-transform: translate(-50%, -50%) rotate(90deg);
              transform: translate(-50%, -50%) rotate(90deg); }

.sidebar {
  height: 100vh;
  position: fixed;
  width: 265px;
  transition: 0.2s;
  left: 0;
  top: 0;
  background-color: #1e1e2d;
  overflow-y: scroll; }
  .sidebar::-webkit-scrollbar {
    width: 0;
    background: transparent; }
  .sidebar__header {
    display: flex;
    background-color: #1a1a27;
    height: 60px;
    align-items: center;
    padding: 20px 28px;
    transition: 0.3s; }
    .sidebar__header path[fill] {
      transition: 0.3s; }
    .sidebar__header:hover .arrow {
      fill: #5d78ff; }
  .sidebar__logo {
    flex-grow: 1; }
    .sidebar__logo__background {
      fill: #86D96C; }
  .sidebar.collapsed {
    width: 70px; }
    .sidebar.collapsed .sidebar__header {
      padding: 20px;
      justify-content: center; }
    .sidebar.collapsed .sidebar__logo {
      display: none; }
    .sidebar.collapsed .sidebar__button {
      transition: 0.3s;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
    .sidebar.collapsed .nav-category {
      font-size: 0; }
    .sidebar.collapsed .nav-item {
      padding: 0 10px;
      justify-content: center; }
      .sidebar.collapsed .nav-item__button, .sidebar.collapsed .nav-item__title {
        display: none; }
    .sidebar.collapsed + .main-container {
      margin-left: 70px;
      width: calc(100% - 70px); }
      .sidebar.collapsed + .main-container .main-navbar {
        width: calc(100% - 70px); }
      .sidebar.collapsed + .main-container .actionbar {
        left: 70px; }
      .sidebar.collapsed + .main-container .breadcrumbs__container {
        left: 70px; }

.main-container {
  margin-left: 265px;
  width: calc(100% - 265px);
  transition: 0.2s; }

.main-navbar {
  width: calc(100% - 265px);
  transition: 0.2s;
  position: fixed;
  z-index: 800;
  top: 0;
  background: white;
  box-shadow: 0px 0px 13px #523f690c; }
  .main-navbar__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px; }

.nav-button {
  display: flex;
  align-items: center;
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s; }
  .nav-button:hover .nav-button__logo::after {
    opacity: 0.2; }
  .nav-button__logo {
    position: relative; }
    .nav-button__logo--right {
      order: 2; }
    .nav-button__logo:after {
      content: "";
      position: absolute;
      transition: 0.3s;
      top: 0;
      left: 0;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      background-color: #fff;
      opacity: 0.1; }

.login-container {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a27; }
  .login-container .nav-button {
    position: absolute;
    top: 20px;
    left: 20px; }
  .login-container::before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 35%;
    -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
    background: linear-gradient(180deg, #86D96C 0%, #276E19 100%); }

.login-wrapper {
  max-width: 425px;
  margin: 40px;
  width: 100%; }

.login-header {
  display: flex;
  justify-content: center;
  padding-bottom: 50px; }

.login-panel {
  position: relative;
  z-index: 2;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px; }

.login-submit {
  text-align: right;
  padding-top: 10px; }

.rc-md-navigation {
  background: #fff; }

.rc-md-editor .editor-container .sec-md .input {
  padding-top: 20px; }
  .rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar {
    display: none; }

.rc-md-editor .tool-bar {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0; }

.m-t-0 {
  margin-top: 0px !important; }

.m-0 {
  margin: 0px !important; }

.p-t-0 {
  padding-top: 0px !important; }

.p-0 {
  padding: 0px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.m-0 {
  margin: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.p-0 {
  padding: 0px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.m-0 {
  margin: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-0 {
  padding: 0px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.m-0 {
  margin: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-0 {
  padding: 0px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-5 {
  margin: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-5 {
  padding: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-5 {
  margin: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-5 {
  padding: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.m-5 {
  margin: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-5 {
  padding: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.m-5 {
  margin: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-5 {
  padding: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-10 {
  margin: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-10 {
  padding: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-10 {
  margin: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-10 {
  padding: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-10 {
  margin: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-10 {
  padding: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.m-10 {
  margin: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-10 {
  padding: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.m-15 {
  margin: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-15 {
  padding: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-15 {
  margin: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-15 {
  padding: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.m-15 {
  margin: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-15 {
  padding: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.m-15 {
  margin: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-15 {
  padding: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-20 {
  margin: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-20 {
  padding: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-20 {
  margin: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-20 {
  padding: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.m-20 {
  margin: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-20 {
  padding: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.m-20 {
  margin: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-20 {
  padding: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.m-25 {
  margin: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-25 {
  padding: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.m-25 {
  margin: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-25 {
  padding: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.m-25 {
  margin: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-25 {
  padding: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.m-25 {
  margin: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-25 {
  padding: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-30 {
  margin: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-30 {
  padding: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-30 {
  margin: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-30 {
  padding: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.m-30 {
  margin: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-30 {
  padding: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.m-30 {
  margin: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-30 {
  padding: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.m-35 {
  margin: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-35 {
  padding: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.m-35 {
  margin: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-35 {
  padding: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.m-35 {
  margin: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-35 {
  padding: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.m-35 {
  margin: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-35 {
  padding: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-40 {
  margin: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-40 {
  padding: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-40 {
  margin: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-40 {
  padding: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.m-40 {
  margin: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-40 {
  padding: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.m-40 {
  margin: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-40 {
  padding: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.m-45 {
  margin: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-45 {
  padding: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.m-45 {
  margin: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.p-45 {
  padding: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.m-45 {
  margin: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.p-45 {
  padding: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.m-45 {
  margin: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-45 {
  padding: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.m-50 {
  margin: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-50 {
  padding: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.m-50 {
  margin: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.p-50 {
  padding: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.m-50 {
  margin: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-50 {
  padding: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.m-50 {
  margin: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-50 {
  padding: 50px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.m-75 {
  margin: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.p-75 {
  padding: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.m-75 {
  margin: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.p-75 {
  padding: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.m-75 {
  margin: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.p-75 {
  padding: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.m-75 {
  margin: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.p-75 {
  padding: 75px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.m-100 {
  margin: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-100 {
  padding: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.m-100 {
  margin: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.p-100 {
  padding: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.m-100 {
  margin: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.p-100 {
  padding: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.m-100 {
  margin: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-100 {
  padding: 100px !important; }

