.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -10px 10px;
  .radio {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid #e2e5ec;
    border-radius: 50%;
    position: relative;

    &.active {
      border-color: #3699ff;

      &:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #3699ff;
        padding: 4px;
        top: 28%;
        left: 21%;
      }
    }
  }

  .form-group {
    margin: 0 10px;
  }
  &:last-child {
    .form-group {
      margin-bottom: 0;
    }
  }
  &.grid-2 {
    .form-group {
      flex-basis: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
  &.grid-3 {
    .form-group {
      flex-basis: calc(33.3% - 20px);
      max-width: calc(33.3% - 20px);
    }
  }
  &.grid-4 {
    .form-group {
      flex-basis: calc(25% - 20px);
      max-width: calc(25% - 20px);
    }
  }
}
.form-group {
  flex-basis: 100%;
  max-width: 100%;
  &.label-aside {
    .inp-wrapper {
      display: flex;
      align-items: center;
      .inp-group,
      .check-group {
        width: 100%;
      }
      label {
        width: initial;
        min-width: 70px;
        text-align: right;
        padding-right: 20px;
        white-space: nowrap;
      }
    }
    &.error {
      .inp-error {
        padding-left: 80px;
      }
    }
  }

  margin-bottom: 15px;

  label {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    color: $label-form;
  }
  &.error {
    label,
    .inp-error {
      color: #ff0000;
    }
    .inp-group {
      input {
        border-color: #ff0000;
      }
    }
    .textarea-group {
      textarea {
        border-color: #ff0000;
      }
    }
    .inp-error {
      font-size: 11px;
      line-height: 14px;
      padding: 5px 0 0 5px;
    }
    .postfix,
    .prefix {
      border-color: #ff0000;
      color: #ff0000;
    }
  }
}

.input-disabled {
  background-color: #f7f8fa;
  font-size: 13px;
  pointer-events: none;
  user-select: none;
}

@import "./input.scss";
@import "./check.scss";

.md-wrapper {
  padding: 15px 0;
  .form-row {
    width: 100%;
    padding: 0 20px;
    margin-left: 0;

    .rc-md-navigation {
      background: #fff;

      .navigation-nav {
        .button-wrap {
          .button {
            display: none;
          }
          .button-type-bold,
          .button-type-italic,
          .button-type-underline {
            display: block;
          }
        }
      }
    }

    .rc-md-editor {
      border-radius: 5px;
    }

    .rc-md-editor .editor-container .sec-md .input {
      padding-top: 20px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .rc-md-editor .tool-bar {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
    }
  }
}
